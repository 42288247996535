export default{
    
    nowUp: ['gentryOfHermitage2','theHeronsCourt','tapestryOfGoldenFlames'],

    defaultResult: {
        star5: [
            '5-Keqing-electric-刻晴',
            '5-Qiqi-ice-七七',
            '5-Mona-water-莫娜',
            '5-Diluc-fire-迪卢克',
            '5-Jean-wind-琴',
        ],
        star4: [
            '4-Yunjin-stone-云堇',
            '4-Gorou-stone-五郎',
            '4-Thoma-fire-托马',
            '4-Sara-electric-九条裟罗',
            '4-Sayu-wind-早柚',
            '4-Yanfei-fire-烟绯',
            '4-Rosaria-ice-罗莎莉亚',
            '4-Xinyan-fire-辛焱',
            '4-Diona-ice-迪奥娜',
            '4-Sucrose-wind-砂糖',
            '4-Chongyun-ice-重云',
            '4-Noelle-stone-诺艾尔',
            '4-Bennett-fire-班尼特',
            '4-Fischl-electric-菲谢尔',
            '4-Ningguang-stone-凝光',
            '4-Xingqiu-water-行秋',
            '4-Beidou-electric-北斗',
            '4-Xiangling-fire-香菱',
            '4-Razor-electric-雷泽',
            '4-Barbara-water-芭芭拉',
            
            '4-Favonius_Sword-sword-西风剑',
            '4-The_Flute-sword-笛剑',
            '4-Sacrificial_Sword-sword-祭礼剑',
            '4-Lion\'s_Roar-sword-匣里龙吟',
            '4-Favonius_Greatsword-claymore-西风大剑',
            '4-The_Bell-claymore-钟剑',
            '4-Sacrificial_Greatsword-claymore-祭礼大剑',
            '4-Rainslasher-claymore-雨裁',
            '4-Dragon\'s_Bane-pike-匣里灭辰',
            '4-Favonius_Lance-pike-西风长枪',
            '4-Favonius_Codex-magic-西风秘典',
            '4-The_Widsith-magic-流浪乐章',
            '4-Sacrificial_Fragments-magic-祭礼残章',
            '4-Mappa_Mare-magic-万国诸海图谱',
            '4-Eye_of_Perception-magic-昭心',
            '4-Favonius_Warbow-bow-西风猎弓',
            '4-The_Stringless-bow-绝弦',
            '4-Sacrificial_Bow-bow-祭礼弓',
            '4-Rust-bow-弓藏',
            '4-Mouun\'s_Moon-bow-曚云之月',
        ],
        star3: [
            '3-Cool_Steel-sword-冷刃',
            '3-Harbinger_of_Dawn-sword-黎明神剑',
            '3-Traveler\'s_Handy_Sword-sword-旅行剑',
            '3-Fillet_Blade-sword-吃虎鱼刀',
            '3-Skyrider_Sword-sword-飞天御剑',
            '3-Ferrous_Shadow-sword-铁影阔剑',
            '3-Bloodtainted_Greatsword-claymore-沐浴龙血的剑',
            '3-White_Iron_Greatsword-claymore-白铁大剑',
            '3-Debate_Club-claymore-以理服人',
            '3-Skyrider_Greatsword-claymore-飞天大御剑',
            '3-White_Tassel-pike-白缨枪',
            '3-Halberd-pike-钺矛',
            '3-Black_Tassel-pike-黑缨枪',
            '3-Magic_Guide-magic-魔导绪论',
            '3-Thrilling_Tales_of_Dragon_Slayers-magic-讨龙英杰谭',
            '3-Otherworldly_Story-magic-异世界行记',
            '3-Emerald_Orb-magic-翡玉法球',
            '3-Twin_Nephrite-magic-甲级玉珏',
            '3-Raven_Bow-bow-鸦羽弓',
            '3-Sharpshooter\'s_Oath-bow-神射手之誓',
            '3-Recurve_Bow-bow-反曲弓',
            '3-Slingshot-bow-弹弓',
            '3-Messenger-bow-信使',
        ]
    }
}